import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { AfterPageProps } from '../../../@sprinx/react-after-razzle';

export type LoginPageinformationSectionProps = AfterPageProps;
export type LoginPageinformationSectionClassNameKey =
  | 'bulletedList'
  | 'informationSection'
  | 'informationSectionPart'
  | 'line'
  | 'contactHeading'
  | 'contactName'
  | 'contactEmail'
  | 'dots'
  | 'portalDiv'
  | 'portal'
  | 'portalFirstRow'
  | 'b2b';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<LoginPageinformationSectionClassNameKey, {}>({
    bulletedList: {
      listStyleType: 'none',
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(-2.5),
      '&>li::before': {
        content: '"-"',
        position: 'absolute',
        marginLeft: theme.spacing(-2.5),
      },
    },
    informationSection: {
      display: 'flex',
      justifyContent: 'space-around',
      marginLeft: 'auto',
      marginRight: 'auto',

      margin: theme.spacing(9, 6),
      flexWrap: 'wrap',
      '@media (min-width: 1161px)': {
        maxWidth: '1300px',
      },
      '@media (max-width: 1160px)': {
        maxWidth: '600px',
      },
    },
    informationSectionPart: {
      '@media (min-width: 1161px)': {
        width: '30%',
      },
      maxWidth: '500px',
      fontSize: theme.spacing(2),
      color: '#515151',
      letterSpacing: '1px',
      padding: theme.spacing(2),
    },
    line: {
      width: theme.spacing(8),
      height: theme.spacing(0.5),
      marginLeft: theme.spacing(0.25),
      marginTop: theme.spacing(0.5),
      background: '#a4db0b',
      border: theme.spacing(0),
    },
    contactHeading: {
      fontWeight: 'bold',
      fontSize: '17px',
    },
    contactName: {
      margin: theme.spacing(2, 0),
    },
    contactEmail: {
      textDecoration: 'none',
      color: '#0F3457',
    },
    dots: {
      width: '100px',
      height: '120px',
      backgroundImage: 'radial-gradient(grey 2px, transparent 14%)',
      backgroundSize: '30px 30px',
      backgroundPosition: '22px -19px',
      display: 'inline-block',
    },
    portalDiv: {
      display: 'flex',
      justifyContent: 'center',
      '@media (min-width: 1161px)': {
        width: '30%',
      },
    },
    portal: {
      fontSize: theme.spacing(10),
      lineHeight: 1,
      color: '#a4db0b',
      width: 'min-content',
      paddingBottom: theme.spacing(3),
    },
    portalFirstRow: {
      display: 'flex',
    },
    b2b: {
      color: '#515151',
      marginTop: theme.spacing(6.25),
    },
  }),
);

const LoginPageInformationSection: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <div className={classes.informationSection}>
      <div className={classes.portalDiv}>
        <div className={classes.portal}>
          <div className={classes.portalFirstRow}>
            <span className={classes.b2b}>B2B</span>
            <div className={classes.dots}></div>
          </div>
          <span>portál</span>
        </div>
      </div>
      <div className={classes.informationSectionPart}>
        
        <ul className={classes.bulletedList}>
          <li>Objednávky dle kódu produktu</li>
          <li>Vytváření nákupních seznamů</li>
          <li>Správa osob a středisek</li>
          <li>Propojení s naším ERP systémem</li>
        </ul>
      </div>
      <div className={classes.informationSectionPart}>
        <div className={classes.contactHeading}>Pro založení B2B účtu nás kontaktujte</div>
        <hr className={classes.line} />
        <div className={classes.contactName}>
          <div>Marek Materna</div>
        </div>
        <div>
          <a className={classes.contactEmail} href='mailto: materna@marccrab.cz'>
            materna@marccrab.cz
          </a>
        </div>
      </div>
    </div>
  );
};
LoginPageInformationSection.displayName = 'LoginPageInformationSection';

export default LoginPageInformationSection;
